import DoughnutChart from "components/ui/admin/Charts/DoughnutChart";
import { AllPhishingResultsResponseDto, Scenario } from "generated/api/apiSchemas";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getPhishingResults, getTooltipContentForPhishingResults } from "..";
import EmptyResult from "components/ui/admin/Dashboard/EmptyResult";
import classNames from "classnames";

export type scenarioLowerCamelCase = "hrAssistant" | "procurement" | "itManager";

interface IProps {
  scenario: scenarioLowerCamelCase;
  onScenarioSelection: (scenario: Scenario) => void;
  phishingResults?: AllPhishingResultsResponseDto;
  className?: string;
  tooltipArrowPlacement?: "left" | "right" | "top";
}

const ScenarioDoughnutCard: FC<IProps> = ({
  scenario,
  onScenarioSelection,
  phishingResults,
  className,
  tooltipArrowPlacement
}) => {
  const [zIndex, setZIndex] = useState(0);

  const intl = useIntl();
  const doughnutData = useMemo(
    () => getPhishingResults(intl, phishingResults?.[scenario]),
    [intl, phishingResults, scenario]
  );

  const hasResults = useMemo(() => {
    return !(
      !!phishingResults &&
      phishingResults[scenario].red.value === 0 &&
      phishingResults[scenario].amber.value === 0 &&
      phishingResults[scenario].green.value === 0
    );
  }, [phishingResults, scenario]);

  return (
    <div
      className={classNames(
        "relative flex w-[19.56rem] min-w-[328px] max-w-[480px] grow flex-col justify-center rounded-7xl border-2 border-neutral-100 py-6 px-8 text-center",
        className
      )}
      style={{ zIndex }}
    >
      <h3 className="header-500 mb-2.5 text-primary-700">
        <FormattedMessage id={`admin.dashboard.resultsByType.${scenario}.title`} />
      </h3>
      <button
        className={classNames("header-500 text-primary-500 underline", !hasResults && "pt-8")}
        onClick={() => {
          switch (scenario) {
            case "hrAssistant":
              onScenarioSelection("HR_ASSISTANT");
              break;
            case "itManager":
              onScenarioSelection("IT_MANAGER");
              break;
            case "procurement":
              onScenarioSelection("PROCUREMENT");
              break;
          }
        }}
      >
        <FormattedMessage id="general.learnMore" />
      </button>
      {hasResults && doughnutData ? (
        <DoughnutChart
          className="mt-6 pt-0"
          isVertical
          chartProps={{ data: doughnutData }}
          tooltipArrowPlacement={tooltipArrowPlacement}
          setZIndex={setZIndex}
          getTooltipContent={args => getTooltipContentForPhishingResults(args, phishingResults?.[scenario])}
        />
      ) : (
        <EmptyResult className="mt-9" />
      )}
    </div>
  );
};

export default ScenarioDoughnutCard;
