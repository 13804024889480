import { ComponentProps, FC, Fragment, HTMLAttributes, ReactNode, useState } from "react";
import classNames from "classnames";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataset,
  CoreScaleOptions,
  TooltipModel,
  TooltipItem,
  DoughnutControllerDatasetOptions,
  ChartDatasetProperties
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Percentage from "components/ui/Percentage/Percentage";
import useInterval from "hooks/useInterval";
import { Transition } from "@headlessui/react";
import _ from "lodash";
import Popover from "components/ui/Popover/Popover";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import colours from "./constants/colours";

ChartJS.register(ArcElement, Tooltip, Legend);

// Override chart default for legend
ChartJS.overrides["doughnut"].plugins.legend = {
  ...ChartJS.overrides["doughnut"].plugins.legend,
  display: false
};

export interface ITooltip {
  colour?: string;
  dataset: _DeepPartialObject<
    {
      type: "doughnut";
    } & DoughnutControllerDatasetOptions
  > &
    ChartDatasetProperties<"doughnut", number[]>;
  raw: string;
  visible: boolean;
  element?: HTMLElement;
}

interface IProps extends HTMLAttributes<HTMLDivElement> {
  chartProps: ComponentProps<typeof Doughnut>;
  getTooltipContent?: (args: { dataset: TooltipItem<"doughnut">["dataset"]; raw: string }) => ReactNode;
  tooltipArrowPlacement?: "left" | "right" | "top";
  isVertical?: boolean;
  setZIndex?: (zIndex: number) => void;
}

const ANIMATION_DURATION = 1500;

const DoughnutChart: FC<IProps> = ({
  className,
  chartProps,
  getTooltipContent,
  isVertical,
  tooltipArrowPlacement = "left",
  setZIndex,
  ...rest
}) => {
  const dataset = chartProps.data.datasets[0] as ChartDataset<"doughnut", any>;
  const [tooltip, setTooltip] = useState<ITooltip | null>(null);

  const [countVisible, setCountVisible] = useState(-1);
  const durationPerDataset = ANIMATION_DURATION / dataset.data.length;

  const intervalRef = useInterval(() => {
    if (countVisible < dataset.data.length) {
      setCountVisible(prev => prev + 1);
    } else {
      window.clearInterval(intervalRef.current);
    }
  }, durationPerDataset);

  const updateTooltip = _.debounce(({ tooltip }: { tooltip: TooltipModel<"doughnut"> }) => {
    const tooltipColours = tooltip?.dataPoints?.[0].dataset.backgroundColor as string[] | undefined;
    const tooltipColour = tooltipColours?.[tooltip?.dataPoints[0].dataIndex ?? 0];

    setTooltip({
      colour: tooltipColour,
      dataset: tooltip?.dataPoints[0].dataset,
      raw: tooltip.dataPoints[0].raw as string,
      visible: tooltip.opacity !== 0
    });
  }, 200);

  const onInteraction = (
    e: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLButtonElement>,
    index: number,
    colour: string,
    value: any
  ) => {
    setZIndex?.(20);
    setTooltip({
      dataset: dataset.data[index],
      colour,
      raw: value,
      visible: true,
      element: e.currentTarget
    });
  };

  return (
    <div
      className={classNames(
        "flex",
        { "min-w-[245px] flex-col gap-6 px-4": isVertical, "gap-[157px] px-7 py-9": !isVertical },
        className
      )}
      {...rest}
    >
      <div className={classNames("flex justify-center", { "max-h-[196px]": isVertical, "max-h-[240px]": !isVertical })}>
        <Doughnut
          options={{
            animation: {
              duration: ANIMATION_DURATION,
              animateRotate: true,
              easing: "linear"
            },
            plugins: {
              tooltip: {
                enabled: false,
                position: "nearest",
                external: updateTooltip
              }
            }
          }}
          {...chartProps}
        />
      </div>

      <div>
        <ul className={classNames("flex h-full flex-col justify-between", { "gap-6": isVertical })}>
          {chartProps.data.labels?.map((item, index) => {
            const value = dataset.data[index];
            const barColours = dataset?.backgroundColor as CoreScaleOptions["backgroundColor"][];
            const colour = barColours[index] as string;
            return (
              <Transition
                show={index <= countVisible}
                as={Fragment}
                enter="transition-all origin-top-left"
                enterFrom="opacity-0 scale-125"
                enterTo="opacity-100 scale-100"
                key={index}
              >
                <li
                  className={classNames("relative z-0 ", {
                    "mx-auto w-full max-w-[225px] gap-2 p-2": isVertical,
                    "gap-3 p-3": !isVertical
                  })}
                  style={{
                    transitionDuration: `${durationPerDataset}ms`
                  }}
                >
                  <div
                    className={classNames("absolute inset-0 -z-10", colour !== colours.red && "opacity-[0.3]")}
                    style={{ backgroundColor: colour === colours.red ? "#FBE6ED" : colour }}
                  ></div>
                  <button
                    className="flex w-full items-center gap-3"
                    onMouseOver={e => onInteraction(e, index, colour, value)}
                    onFocus={e => onInteraction(e, index, colour, value)}
                    onMouseLeave={e => {
                      setZIndex?.(0);
                      setTooltip(null);
                    }}
                  >
                    <span
                      className={classNames("block aspect-square border border-neutral-700", {
                        "h-[21px] w-[21px]": isVertical,
                        "h-[30px] w-[30px]": !isVertical
                      })}
                      style={{ backgroundColor: colour }}
                    />
                    <Percentage
                      value={value}
                      className={classNames(" text-primary-700", {
                        "header-500": isVertical,
                        "header-800": !isVertical
                      })}
                    />
                    <span
                      className={classNames("text-primary-800 text-left", {
                        "body-100": isVertical,
                        "header-400": !isVertical
                      })}
                    >
                      {item as string}
                    </span>
                  </button>
                  {tooltip && tooltip.visible && getTooltipContent && colour === tooltip.colour && (
                    <Popover
                      placement={tooltipArrowPlacement}
                      className={classNames("top-1/2 z-10 min-w-[280px] -translate-y-1/2", {
                        "left-[calc(100%+20px)] ": tooltipArrowPlacement === "left",
                        "right-[calc(100%+20px)]": tooltipArrowPlacement === "right"
                      })}
                    >
                      {getTooltipContent({
                        dataset: { ...tooltip.dataset, backgroundColor: tooltip.colour },
                        raw: tooltip.raw
                      })}
                    </Popover>
                  )}
                </li>
              </Transition>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DoughnutChart;
